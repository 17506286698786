@import "../../styles/mixins/index.scss";

.mint {
  background-color: var(--black-sec);
  padding: 134px 0;
  @include sm {
    padding: 40px 0;
  }
  &__wrap {
    display: flex;
    align-items: center;
    gap: 10%;
    position: relative;
    z-index: 10;
    @include sm {
      flex-direction: column;
    }
  }
}

.bg {
  width: 102vw;
  left: -2vw;
  height: 390px;
  position: absolute;
  top: 0;
  transform: rotate(-4deg);
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      var(--dark-green) 1%,
      transparent 35.99%,
      var(--black-sec) 99%
    );
    position: absolute;
    top: 52%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 11;
  }
  &__img {
    background-image: url("../../assets/mintBg.png");
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: repeat;
  }
}

.card {
  background-color: var(--dark-green);
  border-radius: 20px;
  padding: 26px 75px;
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.33);
  @include sm {
    margin-bottom: 40px;
    padding: 10px 40px;
  }

  &__img {
    border-radius: 50%;
    border: solid 3px var(--green);
    margin-bottom: 16px;
  }

  &__statistics {
    display: inline-block;
    background-color: var(--violet);
    border-radius: 20px;
    padding: 5px 15px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
  }

  &__desc {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 800;
  }
}

.info {
  &__title {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 36px;
    span {
      color: var(--green);
      font-weight: 700;
    }
    @include sm {
      font-size: 30px;
    }
  }
  @include sm {
    text-align: center;
  }
  &__btn {
    background-color: var(--violet);
    font-weight: 800;
    padding: 20px 76px;
    border-radius: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
    max-width: 310px;
    color: var(--white);
    text-transform: uppercase;
    transition: opacity 0.3s;
    &:disabled{
      opacity: 0.5;
    }
    &:hover {
      opacity: 0.9;
    }
    @include sm {
      margin: auto;
    }
  }
}

.price {
  margin-bottom: 32px;
  &__wrap {
    display: flex;
    align-items: center;
    gap: 28px;
    @include sm {
      flex-direction: column;
    }
  }
  &__total {
    margin-top: 8px;
    margin-left: 11px;
  }
  &__text {
    font-size: 30px;
  }
}
