@import '../../styles/mixins/index.scss';

.wrap {
    position: relative;
    background-color: var(--dark-green);
    border-radius: 20px;
}

.card {
    position: relative;
    background-color: var(--dark-green);
    border-radius: 20px;
    padding: 34px;
    max-width: 410px;
    color: var(--white);
    position: relative;
    z-index: 1;

    @include xxl {
        width: calc(100% / 3 - 20px);
    }

    @include md {
        width: calc(50% - 20px);
    }

    @include sm {
        width: 100%;
        max-width: initial;
        text-align: center;
    }

    &__image {
        margin-bottom: 26px;

    }

    &__title {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 30px;
        margin-bottom: 30px;
    }


    &__btn {
        display: block;
        text-align: center;
        background-color: var(--green);
        padding: 20px;
        border-radius: 20px;
        color: var(--white);
        box-shadow: 0px 4px 12px #000000;
        font-size: 16px;
        font-weight: 800;
        width: 100%;
        text-transform: uppercase;
    }
}