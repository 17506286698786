.error {
    background-color: var(--black);
    padding: 200px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    h1 {
        font-size: 120px;
        font-weight: 800;
    }
}