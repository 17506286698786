@import "../../styles/mixins/index.scss";

.footer {
    background-color: var(--black-sec);
    background-image: url('../../assets/footer-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 60px 0;
    &__logo {
        margin-bottom: 40px;
    }
    &__social {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 38px;
        margin: 47px 0;
        a {
            transition: transform .3s;
            &:hover {
                transform: translateY(-5px)
            }
        }
        @include sm {
            gap: 20px;
            flex-wrap: wrap;
        }
    }
}