@import "../../styles/mixins/index.scss";

.rarity {
  background-color: var(--black-sec);
  padding: 134px 0;
  @include sm {
    padding: 40px 0;
  }
}
.table {
  margin: 20px 0;
  width: 100%;
  border: solid var(--white) 1px;
  td {
    padding: 10px;
  }
}
