@import "../../styles/mixins/index.scss";

.intro {
    background-image: url("../../assets/background1.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 800px;

    @include md {
        min-height: initial;
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        gap: 50px;

        @include md {
            flex-direction: column;
            text-align: center
        }
    }

    &-block {
        padding-block-start: 130px;
        flex: 0 0 48%;

        @include md {
            padding-block-start: 40px;
            padding-block-end: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__title {
            font-size: 50px;
            text-transform: uppercase;
            color: var(--dark-green);
            font-weight: 900;
            margin-bottom: 22px;
            @include sm {
                font-size: 38px;
            }
        }

        &__desc {
            &_first {
                color: var(--dark-green);
                margin-bottom: 0;
                font-size: 30px;
                @include sm {
                    font-size: 20px;
                }
            }
            &_second {
                color: var(--dark-green);
                margin-bottom: 38px;
                font-size: 30px;
                @include sm {
                    font-size: 20px;
                }
            }
        }

        &__btn {
            background-color: var(--violet);
            font-weight: 800;
            padding: 16px 36px;
            border-radius: 20px;
            display: flex;
            gap: 20px;
            align-items: center;
            max-width: 310px;
            color: var(--white);
            text-transform: uppercase;
            transition: opacity 0.3s;
            margin-bottom: 100px;

            @include md {
                justify-content: center;
            }

            @include sm {
                margin-bottom: 50px;
            }

            &:hover {
                opacity: 0.9;
            }
        }

        &__btn-mint {
            text-transform: uppercase;
            border: solid var(--green) 3px;
            border-radius: 20px;
            padding: 26px 36px;
            color: var(--white);
            font-size: 40px;
            font-weight: 700;
            transition: box-shadow .3s;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
            @include sm {
                font-size: 32px;
            }
            &:hover {
               box-shadow: none;
            }
            &_icon {
                margin-left: 20px;
            }
        }
    }

    &-block-images {
        position: relative;
        width: 100%;

        @include xl {
            transform: scale(0.7);
        }

        @include md {
            display: none;
        }

        img {
            position: absolute;
            &:not(:last-child) {
                max-width: 284px;
                height: 362px;
                border-radius: 20px;
                border: 3px solid var(--violet);
                filter: drop-shadow(0px -4px 12px rgba(0, 0, 0, 0.33));
            }
            &:nth-child(2) {
                transform: rotate(15deg);
                left: 30%;
                top: 20%;
            }

            &:nth-child(3) {
                transform: rotate(43deg);
                top: 51%;
                right: 0;
            }

            &:nth-child(4) {
                transform: rotate(-20deg);
                top: 80%;
                left: 22%;
            }

            &:nth-child(5) {
                bottom: -56%;
                right: 0;

                @include lg {
                    display: none;
                }
            }
        }
    }
}

.products {
    background-color: var(--black-sec);
    padding-block-start: 176px;
    padding-block-end: 176px;

    @include xl {
        padding-block-start: 100px;
        padding-block-end: 40px;
    }

    @include lg {
        padding-block-start: 40px;
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        flex-wrap: wrap;
        gap: 20px;

        @include xl {
            gap: 10px;
        }

        @include sm {
            flex-direction: column;
            margin-top: 0;
            gap: 30px;
        }
    }

    &__desc {
        position: relative;
        z-index: 1;
    }
}

.video {
    background-color: var(--dark-green);
    padding: 16px 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    text-transform: uppercase;

    &__wrap {
        max-width: 700px;
        width: 100%;
        border-radius: 20px;
    }
 
    &__play {
        border-color: transparent transparent transparent var(--white);
        border-style: solid;
        border-width: 37px 0 37px 60px;
        cursor: pointer;
    }
}

.our-vision {
    background-color: var(--black-sec);
    padding: 160px 0;

    @include xl {
        padding: 40px 0;
    }

    &__title {
        font-size: 50px;
        font-weight: 900;
        text-align: center;
        color: var(--white);
        text-transform: uppercase;
        margin-bottom: 10px;
        @include sm {
            font-size: 38px;
        }
    }

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        color: var(--white);
        gap: 30px;

        @include xl {
            justify-content: center;
            text-align: center;
        }

        @include lg {
            margin-bottom: 30px;
        }

        img {
            height: 290px;

            @include sm {
                height: auto;
            }
        }

        h4 {
            font-size: 16px;
            font-weight: 800;
            text-transform: uppercase;
        }
    }
}

.roadmap {
    background-color: var(--black-sec);
    padding-bottom: 80px;
    background-image: url('../../assets/roadmaps/roadmap-bg1.png'), url('../../assets/roadmaps/roadmap-bg2.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left, right;

    &__title {
        font-size: 50px;
        font-weight: 900;
        text-align: center;
        color: var(--white);
        text-transform: uppercase;
        margin-bottom: 90px;

        @include lg {
            margin-bottom: 20px;
        }
       @include sm {
           font-size: 38px;
       }
    }

    &__images {
        position: absolute;
        left: 0;
        transform: translateY(150px);

        @include lg {
            display: none;
        }

        img {

            &:nth-child(2) {
                top: 250px;
                position: absolute;
                left: 0;
            }

            &:nth-child(3) {
                position: absolute;
                left: 0;
                top: 480px;
            }
        }
    }
}

.timeline {
    padding-left: 200px;

    @include lg {
        padding-left: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        position: relative;
        padding: 22px 0;
    }

    &__img {
        position: relative;
        width: 90px;
        display: flex;
        justify-content: center;

        @include sm {
            display: none;
        }

        img {
            position: relative;
            z-index: 10;
        }

        &::before {
            content: "";
            height: 180px;
            position: absolute;
            left: 50%;
            border-left: 2px var(--white) dashed;

            @include lg {
                height: 220px;
            }
        }

        &::after {
            content: "";
            border-bottom: solid var(--white);
            position: absolute;
            width: 100%;
            top: 50%;
            left: 70%;
        }

        &_last {
            &::before {
                content: "";
                display: none;
            }
        }

        &_1 {
            img {
                transform: rotate(30deg);
            }
        }

        &_2 {
            img {
                transform: rotate(60deg);
            }
        }

        &_3 {
            img {
                transform: rotate(90deg);
            }
        }

        &_4 {
            img {
                transform: rotate(30deg);
            }
        }

        &_5 {
            img {
                transform: rotate(60deg);
            }
        }

        &_6 {
            img {
                transform: rotate(120deg);
            }
        }

        &_7 {
            img {
                transform: rotate(-40deg);
            }
        }
    }

    &__block {
        width: 100%;
        padding: 34px;
        background-color: var(--dark-green);
        border-radius: 20px;
        margin-left: 96px;
        color: var(--white);
        display: flex;
        align-items: center;
        position: relative;
        z-index: 10;

        @include md {
            flex-direction: column;
            margin-left: 20px;
            padding: 20px;
        }

    }

    &__time {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        border-left: solid 1px var(--gray);
        padding-block-end: 24px;
        margin-block-start: 32px;
        color: var(--green);
        font-size: 16px;
        font-weight: 800;

        @include md {
            writing-mode: initial;
            transform: rotate(0);
            border-left: none;
            border-bottom: solid 1px var(--gray);
            padding-block-end: 5px;
            margin-block-start: 0;
            margin-bottom: 10px;
        }

        &_violet {
            color: var(--violet);
        }
    }


}

.collections {
    background-color: var(--black-sec);


    &__title-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 100px;

        img {
            transform: translateX(100px);
        }

        @include xl {
            margin-bottom: 50px;
        }

        @include lg {
            justify-content: center;
            padding-inline-start: 20px;
            padding-inline-end: 20px;

            img {
                display: none;
            }
        }

        @include md {
            margin-bottom: 0;
        }


    }

    &__sub-title {
        color: var(--gray);
        font-weight: 800;
        text-align: center;
        margin-bottom: 20px;
    }

    &__title {
        font-size: 50px;
        color: var(--white);
        text-align: center;
        text-transform: uppercase;
        @include sm {
            font-size: 38px;
        }
    }

    &-cards {
        display: flex;

        @include xxl {
            transform: scale(.8);
        }

        @include xl {
            transform: scale(1);
        }

        @include xl {
            flex-wrap: wrap;
            gap: 20px;
            justify-content: center;
            padding-block-start: 40px;
            padding-block-end: 20px;
        }

    }

    &__wrap {
        background-image: url('../../assets/collections/bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        padding: 0 0 100px 0;
        position: relative;

        @include xl {
            padding: 0;
            justify-content: center;
            text-align: center;
        }

        &::before {
            content: "";
            background: linear-gradient(180deg, #080618 0%, rgba(0, 0, 0, 0) 100%);
            position: absolute;
            top: -90px;
            height: 200px;
            width: 100%;

            @include xl {
                top: 0;
                height: 0px;
            }
        }

        &::after {
            content: "";
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #070517 100%);
            position: absolute;
            bottom: 0;
            height: 200px;
            width: 100%;

            @include xl {
                height: 30px;
                bottom: -10px;
            }

        }
    }



    &__card {
        background-color: var(--violet);
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.33);
        border-radius: 20px;
        min-width: 252px;
        z-index: 1;

        @include xl {
            width: calc(100% / 3 - 20px);
        }

        @include md {
            width: calc(50% - 20px);
        }


        @include sm {
            width: 100%;
        }

        &_1 {
            transform: translate(0, -40px);
        }

        &_2 {
            transform: translate(-22px, 100px);
        }

        &_3 {
            transform: translate(-44px, 0);
        }

        &_4 {
            transform: translate(-88px, 40px);
        }

        &_5 {
            transform: translate(-140px, -100px);
        }

        &_6 {
            transform: translate(-243px, 168px);
        }

        @include xl {
            transform: translate(0, 0);
        }

    }

    &__sphere {
        position: relative;
        top: 100px;
        z-index: 1;

        @include xl {
            display: none;
        }
    }

}

.whitepapers {
    background-color: var(--black-sec);
    padding-block-start: 50px;
    padding-block-end: 100px;

    &__wrap {
        display: flex;
        justify-content: space-between;
        gap: 30px;

        @include xxl {
            flex-wrap: wrap;
            gap: 20px;
        }

    }

    &__sub-title {
        color: var(--gray);
        font-weight: 800;
        text-align: center;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    &__title {
        font-size: 50px;
        color: var(--white);
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 100px;

        @include xl {
            margin-bottom: 40px;
        }
        @include sm {
            font-size: 38px;
        }
    }

}

.chill-time {
    background-color: var(--black-sec);
    background-image: url('../../assets/whitepapers/sphere.png');
    background-repeat: no-repeat;
    padding-block-end: 165px;
    background-position: left center;
    background-size: contain;
    @include sm {
        padding-block-end: 40px;
    }
    &__sub-title {
        color: var(--gray);
        font-weight: 800;
        text-align: center;
        margin-bottom: 20px;
    }

    &__title {
        color: var(--white);
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 26px;
    }

    &__green-text {
        color: var(--green);
        font-size: 30px;
        font-weight: 400;
        text-align: center;
    }
}

.faq {
    background-color: var(--black-sec);
    min-height: 500px;

    &__sub-title {
        color: var(--gray);
        font-weight: 800;
        text-align: center;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    &__title {
        font-size: 50px;
        color: var(--white);
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 80px;
        @include sm {
            font-size: 38px;
        }
    }

    &__collapses {
        margin-bottom: 100px;
    }
}

.howbuy {
    padding-block-end: 40px;

    &__title {
        text-align: center;
        font-size: 50px;
        margin-block-start: 80px;
        text-transform: uppercase;
        margin-bottom: 80px;
        @include sm {
            font-size: 38px;
        }
    }

    &__sub-title {
        color: var(--green);
        text-align: center;
        text-transform: uppercase;
    }
}

.team {
    background-color: var(--black-sec);
    padding-block-start: 100px;
    padding-block-end: 100px;
    background-image: url('../../assets/team/sphere1.png'), url('../../assets/team/sphere2.png'), url('../../assets/team/sphere3.png'), url('../../assets/roadmaps/roadmap-bg1.png');
    background-position: right top, left bottom 240px, right bottom 50px, left;
    background-repeat: no-repeat;

    @include xl {
        background-image: url('../../assets/roadmaps/roadmap-bg1.png');
        background-position: left;
    }

    &__sub-title {
        color: var(--gray);
        font-weight: 800;
        text-align: center;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    &__title {
        font-size: 50px;
        color: var(--white);
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 40px;
        @include sm {
            font-size: 38px;
        }
    }

    &__desc {
        font-size: 18px;
        text-align: center;
    }

    &__wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-block-start: 100px;
        gap: 40px 30px;
    }
}

.contact {
    background-color: var(--black-sec);
    background-image: url('../../assets/collections/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-block-start: 100px;
    padding-block-end: 100px;
    position: relative;

    @include xl {
        padding-block-start: 40px;
        padding-block-end: 40px;
    }

    &__sub-title {
        color: var(--gray);
        font-weight: 800;
        text-align: center;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    &__title {
        font-size: 50px;
        color: var(--white);
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 26px;
        @include sm {
            font-size: 38px;
        }
    }

    &::before {
        content: "";
        background: linear-gradient(180deg, #080618 0%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        top: -35px;
        height: 200px;
        width: 100%;

        @include xl {
            top: 0;
            height: 20px;
        }
    }

    &::after {
        content: "";
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #070517 100%);
        position: absolute;
        bottom: 0;
        height: 200px;
        width: 100%;

        @include xl {
            display: none;
        }

    }
}
