
.collapse {
    position: relative;
    border-bottom: solid 1px var(--green);
    padding: 20px 100px 20px 10px;
    color: var(--white);
    cursor: pointer;
    &::after {
        content: "\002B";
        font-size: 50px;
        display: inline-block;
        font-weight: bold;
        position: absolute;
        top: 8px;
        transition: opacity 0.5s;
        right: 20px;
        &:hover {
            color: var(--mk-violet);
        }
    }
    &_active {
        &::after {
            content: "-";
            font-size: 50px;
            font-weight: bold;
            top: 6px;
        }
    }
    &--title {
        margin-top: 0;
        margin-bottom: 8px;
    }
    &--desc {
        margin-bottom: 0;
        display: none;
        & > p {
            margin-bottom: 10px;
            a {
                color: var(--white);
                text-decoration: underline;
                margin-left: 10px;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &_active {
            overflow: initial;
            display: block;
        }
    }
}