@import "../../styles/mixins/index.scss";

.card {
    padding: 30px;
    color: var(--white);

    &__title {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 800;
    }

    &__soon {
        color: var(--gray);
        margin-bottom: 0;

        &::after {
            content: '';
            display: inline-block;
            background-color: var(--gray);
            width: 25%;
            height: 1px;
            position: relative;
            vertical-align: middle;
            left: 12px;
            @include lg {
                display: none;
            }
        }
    }
}