@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "../mixins/index.scss";

:root {
    --black: #000000;
    --black-sec: #070517;
    --white: #ffffff;
    --red: #dc3545;
    --green: #4efd93;
    --gray: #9c9d9d;
    --dark-green: #04212b;
    --violet: #573bfe;
}

.container {
    max-width: 1320px;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    margin: 0 auto;

    @include xl {
        max-width: initial;
        width: 100%;
    }
}

body {
    font-size: 18px;
    font-family: "Raleway", Arial;
    color: var(--white);
}

body.disableScroll {
    height: 100vh;
    overflow: hidden;
}

img {
    max-width: 100%;
    height: auto;
}

h1,
.h1 {
    font-size: 50px;
    font-weight: 900;
}

h2 {
    font-size: 40px;
    font-weight: 700;
    @include sm {
        font-size: 28px;
    }
}

h3 {
    font-size: 30px;
    font-weight: 400;
    @include sm {
        font-size: 22px;
    }
}

h4 {
    font-size: 14px;
    font-weight: 800;
}

.mb-0 {
    margin-bottom: 0;
}

.text-uppercase {
    text-transform: uppercase;
}

section {
    scroll-margin-block-start: 90px;
}