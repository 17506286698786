.form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 632px;
    margin: 0 auto;

    &__group {
        margin-bottom: 20px;
        width: 100%;
    }

    &__input {
        background-color: var(--dark-green);
        color: var(--gray);
        padding: 20px 40px;
        border-radius: 20px;
        font-family: "Raleway", Arial;
        width: 100%;
    }
    &__msg {
        background-color: var(--dark-green);
        color: var(--gray);
        padding: 20px 40px;
        border-radius: 20px;
        border: none;
        resize: vertical;
        font-family: "Raleway", Arial;
        width: 100%;
    }
    &__btn {
        background-color: var(--green);
        padding: 20px;
        border-radius: 20px;
        text-transform: uppercase;
        color: var(--white);
        font-size: 16px;
        font-weight: 800;
        width: 100%;
        transition: .2s;
        position: relative;
        z-index: 1000;
        &:hover {
            opacity: .9;
        }
    }
    &__error-text {
        color: var(--red);
    }
}