@import '../../styles/mixins/index.scss';

.wrap {
    position: relative;
    background-color: var(--dark-green);
    border-radius: 20px;
    max-width: 32%;
    @include lg {
        max-width: 48%;
    }
    @include sm {
        max-width: 100%;
        text-align: center;
    }
}

.product-card {
    padding: 34px;
    color: var(--white);
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    &__image {
        margin-bottom: 26px;

    }

    &__category {
        text-transform: uppercase;
        margin-bottom: 32px;
        display: flex;
        gap: 10px;
        align-items: center;
        @include sm {
            justify-content: center;
        }

        &_text {
            font-size: 16px;
            font-weight: 800;
            width: max-content;
            display: block;
            white-space: nowrap;
            @include xl {
               white-space: initial;
            }
        }

        &_line {
            background-color: var(--white);
            height: 1px;
            width: 100%;
           @include xl {
               display: none;
           }
        }
    }

    &__title {
        margin-bottom: 10px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &__subtitle {
        color: var(--green);

        font-size: 30px;
        margin-bottom: 16px;
    }

    &__desc {
        margin-bottom: 30px;
        &>p {
            margin-bottom: 12px;
        }
    }

    &__more {
        max-height: 0;
        overflow: hidden;
        transition: max-height .3s;

        &_active {
            max-height: 800px;
        }
    }

    &__btn {
        background-color: var(--green);
        padding: 20px;
        border-radius: 20px;
        color: var(--white);
        box-shadow: 0px 4px 12px #000000;
        font-size: 16px;
        font-weight: 800;
        width: 100%;
        margin-top: auto;
        text-transform: uppercase;
    }


    &__sphere {
        position: absolute;
        bottom: 0;
        right: 0;
    
        &_1 {
            right: 0;
            border-bottom-right-radius: 20px;
        }

        &_4 {
            left: 0;
        }
    }
}