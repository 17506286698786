@import "../../styles/mixins/index.scss";

.header {
  color: var(--white);
  transition: background-color 0.3s;
  position: relative;
  z-index: 10;
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
  }
  &__logo {
    max-width: 190px;
    height: 70px;
    transition: height .3s;
  }
  &_fixed {
    width: 100%;
    top: 0;
    position: fixed;
    z-index: 100;
    background-color: var(--dark-green);
    .menu a {
      color: var(--white);
      font-size: 16px;
    }
    .header__logo {
        height: 50px;
    }
  }
  &_dark {
    background-color: var(--dark-green);
    .menu a {
      color: var(--white);
    }
  }
}

.menu {
  display: flex;
  gap: 34px;
  @include xl {
    gap: 20px;
  }
  @include lg {
    flex-direction: column;
    position: absolute;
    gap: 16px;
    top: 80px;
    display: none;
    z-index: 1000;
    &__open {
      display: flex;
      width: 100%;
      left: 0;
      padding-inline-start: 20px;
      background-color: var(--dark-green);
      height: 100vh;
      padding-block-start: 20px;
      padding-block-end: 20px;
      overflow: auto;
    }
  }
  .menu__link {
    font-weight: 800;
    color: var(--dark-green);
    text-transform: uppercase;
    transition: 0.3s;
    @include xl {
      font-size: 16px;
    }
    &:hover,
    &:active {
      color: var(--white);
    }
    @include lg {
      color: var(--white);
    }
  }
}

.burger {
  &__btn {
    cursor: pointer;
    display: none;
    background: none;
    @include lg {
      display: block;
      height: 24px;
      width: 30px;
    }
  }
  &__icon {
    display: block;
    background-color: var(--white);
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
    position: absolute;

    &:before {
      @extend .burger__icon;
      content: "";
      margin-top: -8px;
    }
    &::after {
      @extend .burger__icon;
      content: "";
      margin-top: 8px;
    }
    &_open {
      background: transparent;
      &::before {
        margin-top: 0px;
        transform: rotate(405deg);
      }
      &::after {
        margin-top: 0px;
        transform: rotate(-405deg);
      }
    }
  }
}
