.card {
    max-width: 247px;
    text-align: center;

    &__image {
        margin-bottom: 26px;
        border-radius: 50%;
        border: solid 3px var(--violet);
    }

    &__title {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 30px;
        margin-bottom: 20px;
    }

    &__position {
        font-weight: 400;
        color: var(--green);
        margin-bottom: 28px;
        min-height: 50px;
    }

    &__social {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 26px;

        img {
            max-width: 32px;
        }
    }
}